<template>
  <v-container
    fill-height
    fluid
  >
    <v-row
      justify="center"
      align="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
      >
        <v-card elevation="8">
          <v-card-title
            :class="`${
              $vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'
            }`"
          >
            {{ step === 2 ? $t("login.titleStep2") : $t("login.title") }}
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="false"
            >
              <v-row v-if="step === 1">
                <v-col cols="12">
                  <v-text-field
                    ref="email"
                    v-model="email"
                    :rules="validationRules.userEmail"
                    :label="$t('login.email')"
                    :placeholder="$t('login.email')"
                    :readonly="true"
                    hide-details="auto"
                    required
                    @keydown.enter="send"
                    @click="(e) => (e.target.readOnly = false)"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    ref="password"
                    v-model="password"
                    type="password"
                    :rules="validationRules.userPassword"
                    :label="$t('login.password')"
                    :placeholder="$t('login.password')"
                    hide-details="auto"
                    required
                    @keydown.enter="send"
                  />
                </v-col>
                <v-col cols="6">
                  <v-checkbox
                    id="rememberMe"
                    ref="rememberMe"
                    v-model="rememberMe"
                    name="rememberMe"
                    :label="$t('login.rememberMe')"
                    :hide-details="true"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="text-right"
                >
                  <v-btn
                    plain
                    :color="`rgba(255, 255, 255, 0)`"
                    @click="saml"
                  >
                    <v-img
                      :loading="true"
                      class="float-right"
                      src="/img/google-saml.png"
                      :alt="$t('SAML')"
                      :title="$t('SAML')"
                      width="48"
                    />
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-else-if="step === 2">
                <v-col cols="12">
                  <div
                    class="ma-auto position-relative"
                    style="max-width: 300px"
                  >
                    <v-otp-input
                      ref="otp"
                      v-model="token"
                      :disabled="loading"
                      type="number"
                      required
                      @finish="send"
                    />
                  </div>
                  <!--v-text-field
                    ref="token"
                    v-model="token"
                    :rules="validationRules.token"
                    :label="$t('2FA code')"
                    :placeholder="$t('Two-factor authentication code')"
                    :autofocus="true"
                    hide-details="auto"
                    required
                    @keydown.enter="send"
                    @click="(e) => (e.target.readOnly = false)"
                  /-->
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-btn
              color="primary"
              text
              @click="send"
            >
              {{ $t("login.signIn") }}
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="step === 2"
              color="error"
              text
              @click="reset"
            >
              {{ $t("login.reset") }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <div class="text-center">
          <v-overlay :value="overlay">
            <v-progress-circular
              indeterminate
              size="64"
            />
          </v-overlay>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Storage from '@/libs/storage/Storage'
export default {
  name: 'App',
  metaInfo() {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('login.meta.title')} — %s`
    }
  },
  data() {
    return {
      loading: false,
      valid: true,
      email: '',
      password: '',
      token: '',
      step: 1,
      rememberMe: Storage.getShouldPersist() === true,
      validationRules: {
        userEmail: [
          (v) => !!v || this.$t('login.validation.field_is_required'),
          (v) =>
            (v && v.length > 6) || this.$t('login.validation.min_len', [6]),
          (v) =>
            (v && v.length < 50) || this.$t('login.validation.max_len', [50]),
          (v) =>
            /.+@.+\..+/.test(v) ||
            this.$t('login.validation.email_must_be_valid')
        ],
        userPassword: [
          (v) => !!v || this.$t('login.validation.field_is_required'),
          (v) =>
            (v && v.length > 8) || this.$t('login.validation.min_len', [8])
        ],
        token: [
          (v) => this.step < 2 || !!v || this.$t('login.validation.field_is_required'),
          (v) =>
            this.step < 2 || (v && v.length === 6) || this.$t('login.validation.token_len', [6]),
          (v) =>
            /^\d+$/.test(v) ||
            this.$t('login.validation.only_digits')
        ]
      },
      samlLoginLink: `${process.env.VUE_APP_API_URL}/auth/saml/login`,
      overlay: false
    }
  },
  watch: {
    overlay(val) {
      val &&
        setTimeout(() => {
          this.overlay = false
        }, 3000)
    }
  },
  mounted() {
    this.overlay = false
  },
  beforeDestroy() {
    this.overlay = false
  },
  methods: {
    async send() {
      try {
        const v = this.$refs.form.validate()
        if (v) {
          this.loading = true
          const dataStep1 = {
            email: this.email,
            password: this.password,
            step: this.step,
            rememberMe: this.rememberMe || false
          }
          const dataStep2 = {
            email: this.email,
            password: this.password,
            step: this.step,
            token: this.token,
            rememberMe: this.rememberMe || false
          }
          const redirectPath = this.$route.query.redirect || null
          const response = await this.$store.dispatch('auth/login', this.step === 2 ? dataStep2 : dataStep1)
          if (response.twoFactorEnabled === true && this.step === 1) {
            this.loading = false
            this.step = 2

            this.$nextTick(() => {
              this.$refs.otp.focus()
            })

            return false
          }
          if (this.$store.getters['auth/isAuthenticated']) {
            await this.$router.push(
              redirectPath !== null
                ? { path: redirectPath }
                : { name: 'dashboard' }
            )
            await this.$toast.success(this.$t('login.messages.successAuth'))
          } else {
            await this.$toast.error(this.$t('login.errors.errorAuth'))
          }
        }
      } catch (e) {
        this.token = ''

        setTimeout(() => {
          this.$refs.otp.focus()
        }, 50)

        await this.$toast.error(e.response.data.message)
      } finally {
        this.loading = false
      }
      return false
    },
    saml() {
      this.overlay = true
      window.location.href = `${this.samlLoginLink}`
    },
    reset() {
      this.step = 1
      this.loading = false
      this.email = ''
      this.password = ''
      this.token = ''
      this.valid = true
    }
  }
}
</script>
